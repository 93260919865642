import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _createSvgIcon2 from "../createSvgIcon";
import * as _Check3 from "@rsuite/icon-font/lib/application/Check";

var _Check2 = "default" in _Check3 ? _Check3.default : _Check3;

var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(_createSvgIcon2);

var _Check = _interopRequireDefault(_Check2); // Generated by script, don't edit it please.


var Check = (0, _createSvgIcon["default"])({
  as: _Check["default"],
  ariaLabel: "check",
  category: "application",
  displayName: "Check"
});
var _default = Check;
exports["default"] = _default;
exports = exports.default;
export default exports;